import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import {
  Container,
  Seo,
  Glass,
  Grid,
  Link,
  // LocalizedLink,
  Localized,
  Typography,
} from 'src/app/components';

const seo: SeoObject = {
  title: 'Admin area',
};

const AdminAreaPage = ({ location, pageContext }: AppProps) => (
  <>
    {/* <CookieConsent /> // FIXME */}
    <Seo location={location} pageContext={pageContext} seo={seo} />
    <div className='flex flex-col gap-4'>
      <div>
        <h4 className='text-h3 font-bold'>
          Admin area links
        </h4>
      </div>
      <div>
        <Link href={'/admin/system'}>
          <Localized
            dictKey={'staticLocales:pages./admin.pages./system.link label'}
          />
        </Link>
      </div>
      <div>
        <Link href={'/admin/partner'}>
          <Localized
            dictKey={'staticLocales:pages./admin.pages./partner.link label'}
          />
        </Link>
      </div>
    </div>
  </>
);

export default AdminAreaPage;
